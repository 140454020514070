<template>
  <v-container class="background" fluid :style="'background-image: url('+require('@/assets/images/titlePicture.jpg')+')'">
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-row  class=" ">
      <v-col cols="12" class="d-flex justify-center align-center" style="height: 100vh">
        <v-card class="pa-9 transparent-card rounded-xl">
          <h3 class="pb-5 text-left">Passwort zurücksetzen</h3>
          <v-text-field
              label="Neues Passwort*"
              type="password"
              outlined
              dense
              v-model="password"
              :rules="[rules.required, rules.min, rules.specialCharacter]"/>
          <v-text-field
              label="Passwort bestätigen*"
              type="password"
              dense
              outlined
              v-model="confirmPassword"
              :rules="[rules.required, rules.min, rules.specialCharacter, rules.confirmPasswordRules]"/>
          <v-btn block color="primary" @click="resetPassword()">Passwort zurücksetzen</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {error, msgObj, success} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");

export default {
  name: "ResetPassword",
  components:{
    Message
  },
  data() {
    return {
      message: msgObj(),
      email: "",
      password: '',
      confirmPassword: '',
      secret: this.$route.query.secret,
      userId: this.$route.query.userId,
      alert: false,
      rules: {
        required: value => !!value || 'Erforderlich',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Ungültige E-mail'
        },
        min: v => v.length >= 8 || 'Mindestens 8 Zeichen',
        specialCharacter:  v=> {
          const format = /[!@#$%^&*()_+\-={};':"\\|,.<>?]+/;
          return format.test(v) || 'Sonderzeichen sind erforderlich';
        },
        confirmPasswordRules: v => v === this.user.password || "Passwörter müssen gleich sein",
      },
    }
  },
  methods: {
    resetPassword() {
        this.$store.dispatch('auth/resetPassword', {
          userId: this.userId,
          secret: this.secret,
          password: this.password,
          confirmPassword: this.confirmPassword,
        }).then(()=>{
          this.message = success("Passwort erfolgreich geändert!")
          setTimeout(() => {
            this.message.show = false
            this.$router.push('/login')
          }, 1000);

        }).catch((err)=>{
          this.message = error(err)
        })

    },
  }
}
</script>

<style scoped>
.transparent-card{
  backdrop-filter: blur(9px) !important;
  background-color: rgba(255, 255, 255, 0.75) !important;
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}
.background{
  height: 102vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left;
}
</style>
